You can add global styles to this file, and also import other style files


body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import '~ngx-toastr/toastr';
.example-card {
  margin: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #BED8FF;
  text-align: center;
}
.label {
  text-align: left;
  font: normal normal normal 25px/42px Poppins;
  letter-spacing: 0px;
  color: #174A84;
  opacity: 1
}

.breakline {
  border-bottom: 0.5px solid #AABCD2;
  margin: 10px 20px 20px 20px;
}

.example-spacer {
  flex: 1 1 auto;
}

table {
  width: 100%;
}

.addButton {
  background-color: #174A84 !important;
  color: white !important;
  font-size: small;
}

.form-label {
  /* margin-top: 0.5rem; */
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #174A84 !important;
  opacity: 1;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}
.example-second-container{
  background-color: #F8FCFF;
  
}
.imageheight{
  width:80%;
  height:80%;
  margin: 10px;
}

.primarytheme{
  color: #174A84 !important;
}

.button-success {
  background: #1ABB9C 0% 0% no-repeat padding-box !important;
   border-radius: 5px; 
   margin-right: 10px; 
   color: #FFFF !important;
}

.button-danger{
  background: #C82333 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  color: #FFFF  !important;
}


.mb-20{
  margin-bottom: 20px;
}
.ml-10{
  margin-left: 10px
}
.mr-10{
  margin-right: 10px;
}
.mb-10{
  margin-bottom: 20px;
}
.mt-15{
  margin-top: 15px
}
.mt-10{
  margin-top: 10px
}
.p-20{
  padding: 20px;
}
.p-8{
  padding: 8px;
}
.mt-25{
  margin-top: 25px
}
 .ml-24{
  margin-left: 24px;
 }

.hr-lines{
  position: relative;
  text-align: left;
  font: normal normal 600 16px/9px Segoe UI;
  letter-spacing: 0px;
  color: #174A84A6;
  opacity: 0.75;
}
.hr-lines:after{
  content:" ";
  height: 0.5px;
  width: 90%;
  background: #AABCD2;
  display: block;
  position: absolute;
  top: 50%;
  left: 10%;
}

.td-header-tiltle{
  background-color: #174A84;
    color: #ffff;
    font-size: 13px;

}
.mat-elevation-z12 th{
  background: #174a84;
  color: #ffffff;
  font-size:13px;
}
.titlestyle{
  background-color: #174A84;
    color: #ffff;
}


.mat-mdc-card-content th{
  background: #174a84;
  color: #ffffff;
  font-size:13px;
}
.mat-drawer-inner-container{ overflow: hidden !important; }

.dropdown-content {
  position: relative;
  display: inline-block;
}
.content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  width:92%;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  text-align: left;
}
.content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.mat-drawer-content[_ngcontent-ng-c2586982196] {
  min-height: 100vh;
}

